body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #fafafa;
}
@keyframes autofill {
  to {
    background: transparent;
  }
}
input:-webkit-autofill {
  animation-name: autofill;
  animation-fill-mode: both;
}
